import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { HeaderService } from '../services/header.service';

export const headerFooterResolver: ResolveFn<boolean> = (
  route: ActivatedRouteSnapshot
) => {
  const headerService = inject(HeaderService);
  headerService.currentRegion = route.params.region as string;
  return true;
};

// Let's keep this in case we want to restore the api later
// export const headerFooterResolver: ResolveFn<Observable<boolean>> = (
//   route: ActivatedRouteSnapshot
// ) => {
//   const headerService = inject(HeaderService);
//   headerService.currentRegion = route.params.region as string;
//   const apiUrl = `${
//     environment.goreApiBases[headerService.currentRegion as keyof object]
//   }header-footer-rest`;
//   return getHeaderFooterResolver(apiUrl);
// };
