import { HeaderFooterService } from '@libs/cross-plat/helpers/header-footer.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GORE_URLS } from '@cpc-mpd/constants/urls.constants';
import { HeaderService } from '@cpc-mpd/services';
import { NotShareMessageComponent } from '../not-share-message/not-share-message.component';

@Component({
  selector: 'mpd-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  mpdUrl: string;
  currentRegion: string;
  // footerHtml: string;

  constructor(
    private readonly headerService: HeaderService,
    private readonly dialog: MatDialog // private readonly headerFooterService: HeaderFooterService
  ) {
    this.currentRegion = this.headerService.currentRegion;
    this.mpdUrl = `https://${GORE_URLS[this.currentRegion as keyof object]}/`;
    // this.footerHtml = this.headerFooterService.headerFooterJson()
    //   ?.footer as string;
  }

  showMessage() {
    this.dialog.open(NotShareMessageComponent, {
      maxWidth: '700px',
    });
  }
}
