import { AfterViewInit, Component, LOCALE_ID, Inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { HeaderFooterService } from '@libs/cross-plat/helpers/header-footer.service';
import { HeaderService } from '@cpc-mpd/services/header.service';
import { GORE_REGIONS, GORE_URLS } from '@cpc-mpd/constants/urls.constants';

@Component({
  selector: 'mpd-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements AfterViewInit {
  headerHtml: string;
  homepageUrl = '';
  regionName = '';

  constructor(
    private readonly sanitizer: DomSanitizer,
    private readonly headerFooterService: HeaderFooterService,
    private readonly headerService: HeaderService
  ) {
    const headerHtmlNoSanitized =
      this.headerFooterService.headerFooterJson()?.header;
    this.headerHtml = this.sanitizer.bypassSecurityTrustHtml(
      headerHtmlNoSanitized as string
    ) as string;
  }

  ngAfterViewInit(): void {
    // MPD
    this.setRegionInHtml(this.headerService.currentRegion);
    this.regionName =
      GORE_REGIONS[this.headerService.currentRegion as keyof object];

    this.homepageUrl = `https://www.${
      GORE_URLS[this.headerService.currentRegion as keyof object]
    }`;
    // Detect when user change language
    const regionLanguageSelector = document.getElementById(
      'region-select'
    ) as HTMLSelectElement;
    regionLanguageSelector.addEventListener('change', () => {
      const regionLanguageCode = regionLanguageSelector.value;
      this.changeRegionMpd(regionLanguageCode);
    });

    // Hide Japanese option in MPD
    document.querySelector('option[value="jp"]')?.remove();
  }

  public changeRegionMpd(regionCode: string): void {
    const oldUrl = window.location.href.split('/');
    if (oldUrl[3] != regionCode) {
      oldUrl[3] = regionCode;
      const newUrl = oldUrl.join('/');
      window.location.replace(newUrl);
    }
  }

  private setRegionInHtml(regionCode: string): void {
    const htmlBody = document.getElementsByTagName('body')[0];
    htmlBody.setAttribute('data-current-locale', regionCode);
    htmlBody.setAttribute(
      'data-locales-avail',
      `[{"id":"${regionCode}","url":"${window.location.href}"}]`
    );
    const optionSelected = document.querySelectorAll(
      '#region-select option[value="' + regionCode + '"]'
    )[0];
    optionSelected.setAttribute('selected', 'true');
  }
}
