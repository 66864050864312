<div class="container" fxLayout="row" fxLayoutAlign="left">
  <div fxFlex="100%">
    <h1>Stay Connected</h1>
    <h2>
      Yes, I would like to register to receive marketing communications from
      Gore Medical Products Division.
    </h2>
  </div>
</div>

<div class="container header-p" fxLayout="row" fxLayoutAlign="left">
  <div fxFlex="100%">
    <p>
      You will receive communications based on the preferences you select and
      can manage or unsubscribe at any time.
    </p>
  </div>
</div>

<div
  class="container"
  fxLayout="row"
  fxLayoutAlign="left"
  *ngIf="isUnsubscribed">
  <div fxFlex="100%">
    <cpc-info-message
      message="You are currently not subscribed. To continue to receive e-mails and manage your preferences, please resubscribe here."></cpc-info-message>
  </div>
</div>

<form>
  <h2>Please provide the following information:</h2>
  <p class="asterisk-p">
    Fields marked with an asterisk (<span cpcStar [removeSpace]="true"></span>)
    are required
  </p>
  <mpd-contact-info></mpd-contact-info>
  <cpc-separator></cpc-separator>
  <div
    class="container title"
    fxLayout="row"
    fxLayoutGap="2%"
    fxLayoutAlign="left">
    <h2>Professional Information</h2>
    <!-- <span class="material-icons info" #info> info_outline </span> -->
  </div>
  <!-- Show the paragraph and checkbox grouping for Areas of Interest when there are any. -->
  <mpd-function-selector></mpd-function-selector>
  <!-- <div *ngIf="areasOfInterest.length > 0">
    <p>
      The following areas of interest have been pre-selected based on the
      specialty you have selected. You can deselect from receiving
      communications by individually un-checking the box next to each area of
      interest.
    </p>
    <cpc-checkbox-grouping
      [form]="formDataService.areasOfInterestCtrl"
      [data]="areasOfInterest"
      title="Select Areas of Interest:"
      errorMessage="Please select at least one Area of Interest."></cpc-checkbox-grouping>
  </div>
  <cpc-checkbox-grouping
    #marketingPref
    [form]="formDataService.preferencesCtrl"
    [data]="preferences"
    title="Select Marketing Preferences:"
    errorMessage="Please select at least one Marketing Preference Topic."></cpc-checkbox-grouping>
  <cpc-separator></cpc-separator> -->
  <mpd-professional-info></mpd-professional-info>
  <cpc-separator></cpc-separator>
  <p class="small-body">
    {{ consentText }}
  </p>

  <div
    class="container submit-row"
    fxLayout="row"
    fxLayout.lt-md="column"
    fxLayoutGap="2%"
    fxLayoutAlign="left">
    <div fxFlex="85%" fxFlex.lt-md="100%">
      <cpc-error-summary
        *ngIf="
          clickedSubmit &&
          formDataService.formGroup.invalid &&
          (formDataService.formGroup.touched || formDataService.formGroup.dirty)
        "
        message="You are missing at least one required field, please review your contact and professional information and try again."></cpc-error-summary>
      <cpc-error-summary
        *ngIf="failedSubmit"
        message="An error occurred while trying to save. Please try again."></cpc-error-summary>
      <cpc-error-summary
        *ngIf="userExists"
        message="A user with this email address already exists. If this is your email address, please click on Manage Preferences when you receive your next communication."></cpc-error-summary>
    </div>
    <div fxFlex="15%" fxFlex.lt-md="100%">
      <div class="vertical-wrapper wide-only">
        <div class="right">
          <button
            mat-flat-button
            type="button"
            color="primary"
            (click)="submit()">
            Submit Registration
          </button>
        </div>
      </div>
    </div>
  </div>

  <h3 class="small-body bold privacy-title">Your Privacy Rights</h3>
  <p class="small-body">
    You have several rights related to the personal information we process about
    you. To understand and exercise these rights,
    <!--
      Ensure you keep the start of the closing anchor tags on the same line as the text.
      Otherwise, you get an extra space within the tag.
    -->
    <a [href]="contactUsUrl" target="_blank" rel="noopener noreferrer">
      click here</a
    >. Please refer to the
    <a [href]="privacyUrl" target="_blank" rel="noopener noreferrer">
      Privacy Notice</a
    >
    for more information.
  </p>
</form>
