import { Component } from '@angular/core';
declare let OneTrust: any;

@Component({
  selector: 'mpd-not-share-message',
  templateUrl: './not-share-message.component.html',
  styleUrls: ['./not-share-message.component.scss'],
})
export class NotShareMessageComponent {
  optedOut = false;
  optOutSharing() {
    OneTrust.UpdateConsent('Category', 'C0004:0');
    this.optedOut = true;
  }
}
