<div class="not-share-dialog">
  <div mat-dialog-content class="dialog-content">
    <ng-container *ngIf="!optedOut; else successfullyOptedOut">
      <p class="body">
        Residents of some states have the right to opt-out of the “sharing” or
        “selling” of their personal information. Gore does not "sell" the
        personal information of website users. Gore may "share" the personal
        information of website users, as standard advertising practice meant to
        offer you a more personalized service. If you want to opt-out from Gore
        “sharing” your personal information, click on Opt-Out of Sharing to turn
        off the targeting cookies that we share with our advertising partners in
        order to provide you with personalized information. Disabling sharing
        will stop the use of cookies to targeted advertising but you may still
        see generic Gore ads. Please note that your exercising of the rights
        outlined will have no adverse effect on the price and quality of our
        goods or services.
      </p>
    </ng-container>
    <ng-template #successfullyOptedOut>
      <p class="opted-message-title">
        You Have Successfully Opted Out of the Sharing of Your Personal
        Information
      </p>
      <p class="opted-message-body">
        Please note that if you clear your cookies and then use another device
        or browser, etc. your opt-out status is not transferred to the new
        device or browser.
      </p>
    </ng-template>
  </div>
  <div mat-dialog-actions class="dialog-actions center" *ngIf="!optedOut">
    <button
      mat-flat-button
      type="button"
      color="primary"
      (click)="optOutSharing()">
      Opt-Out of Sharing
    </button>
  </div>
</div>
