<div
  class="container"
  fxLayout="row"
  fxLayout.lt-md="column"
  fxLayoutGap="2%"
  fxLayoutAlign="left">
  <div fxFlex="50%" fxFlex.lt-sm="100%">
    <div class="group">
      <label for="organization">Primary Hospital or Organization:</label>
      <mat-form-field appearance="outline">
        <input
          matInput
          placeholder="Enter Hospital or Organization"
          [formControl]="form.hospitalCtrl"
          id="organization" />
      </mat-form-field>
      <cpc-error-collection [source]="form.hospitalCtrl"></cpc-error-collection>
    </div>
  </div>
  <!-- <div fxFlex="50%" fxFlex.lt-sm="100%">
    <div class="group">
      <label cpcStar>Language:</label>
      <mat-form-field appearance="outline">
        <mat-select
          [formControl]="form.languageCtrl"
          placeholder="Select Language"
        >
          <mat-option *ngFor="let option of languages" [value]="option">
            {{ option.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <app-error-collection [source]="form.languageCtrl"></app-error-collection>
    </div>
  </div> -->
</div>

<div
  class="container"
  fxLayout="row"
  fxLayout.lt-md="column"
  fxLayoutGap="2%"
  fxLayoutAlign="left">
  <div fxFlex="50%" fxFlex.lt-sm="100%">
    <div class="group">
      <div class="left">
        <label class="normal" for="fellow">Are you a Fellow?</label>
      </div>
      <div class="right toggle">
        <mat-slide-toggle
          class="slide"
          aria-label="fellow"
          [formControl]="form.fellowCtrl"
          color="primary"
          name="fellow"
          labelPosition="before"
          (change)="onIsFellowChange($event.checked)"
          id="fellow">
        </mat-slide-toggle>
      </div>
    </div>
    <div class="left">
      <cpc-error-collection [source]="form.fellowCtrl"></cpc-error-collection>
    </div>
    <div class="group fellow-info">
      <p class="small-body">
        This allows us to provide updates on services and programs related to
        fellowship and early stage career.
      </p>
    </div>
  </div>
  <div fxFlex="50%" fxFlex.lt-sm="100%">
    <div class="group" *ngIf="showFellowshipCompletion">
      <label cpcStar for="fellowshipCompletion"
        >When will your fellowship be completed?:</label
      >
      <mat-form-field appearance="outline">
        <mat-select
          [formControl]="form.fellowshipCompletedCtrl"
          placeholder="Select"
          id="fellowshipCompletion">
          <mat-option *ngFor="let option of years" [value]="option">
            {{ option }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <cpc-error-collection
        [source]="form.fellowshipCompletedCtrl"></cpc-error-collection>
    </div>
  </div>
</div>
