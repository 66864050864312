import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { environment } from '@cpc-mpd/environment';
import { Preferences, Specialty, WorkFunction } from '@cpc-mpd/models';
import { FormDataService } from '@cpc-mpd/services';
import { Country, MpdRoutes, SelectableItem } from '@libs/cross-plat';
import { FormHelperService } from '@libs/cross-plat/validators';
import {
  ConsentService,
  LocationService,
  MarketingPreferencesService,
  RouterService,
  TitleService,
  UserService,
  PreferencesService,
} from '@libs/ui-cdk';
import { UnsubscribeModalComponent } from '@libs/ui-cdk/components';
import { ManagePreferencesBaseComponent } from '@libs/ui-cdk/components/base';
import * as $ from 'jquery';
import { combineLatest } from 'rxjs';
import { ApiHelperService } from '@libs/ui-cdk';
import { GORE_URLS } from '@cpc-mpd/constants/urls.constants';
import { HeaderService } from '@cpc-mpd/services';

@Component({
  selector: 'mpd-manage-preferences',
  templateUrl: './manage-preferences.component.html',
  styleUrls: [
    '../stay-connected/stay-connected.component.scss',
    './manage-preferences.component.scss',
  ],
})
export class ManagePreferencesComponent
  extends ManagePreferencesBaseComponent
  implements OnInit, AfterViewChecked
{
  public consentText = '';
  public countryId = '';
  public functionId = '';
  public preselectedAreas: SelectableItem[] = [];
  public preferences: SelectableItem[] = [];
  public specialtyId = '';
  public latestConsentVersion = false;

  private countries: Country[] = [];
  private readonly router: RouterService;
  private lastSpecialty?: Specialty = undefined;
  private lastAreas: SelectableItem[] = [];

  public get areasOfInterest(): SelectableItem[] {
    let areas: SelectableItem[] = [];

    const specialty = this.formDataService.specialtyCtrl.value as Specialty;
    const country = this.formDataService.countryCtrl.value as Country;

    if (specialty) {
      if (
        this.lastSpecialty?.id === specialty.id &&
        this.lastAreas.length > 0
      ) {
        return this.lastAreas;
      }

      this.lastSpecialty = specialty;
      const currentCountry = this.countries.find(
        (c: Country) => country.id === c.id
      );

      if (!currentCountry) {
        areas = specialty.areasOfInterest;
      } else {
        areas = specialty.areasOfInterest.filter(
          area =>
            area.regions?.includes(environment.globalRegion) ||
            area.regions?.includes(currentCountry.region) ||
            area.regions?.includes(currentCountry.subregion)
        );
      }
    } else {
      const func = this.formDataService.workFunctionCtrl.value as WorkFunction;
      this.lastSpecialty = undefined;

      if (func?.areasOfInterest) {
        areas = func.areasOfInterest;
      }
    }

    this.lastAreas = areas;

    return areas;
  }

  public get contactUsUrl(): string {
    return environment.contactUrl;
  }

  public get privacyUrl(): string {
    return environment.privacyUrl;
  }

  constructor(
    public readonly formDataService: FormDataService,
    private readonly marketingService: MarketingPreferencesService,
    private readonly preferencesService: PreferencesService<Preferences>,
    private readonly consentService: ConsentService,
    private readonly locationService: LocationService,
    private readonly dialog: MatDialog,
    private readonly formHelper: FormHelperService,
    private readonly userService: UserService,
    private readonly apiHelperService: ApiHelperService,
    private readonly headerService: HeaderService,
    private cdref: ChangeDetectorRef,
    titleService: TitleService,
    routerService: RouterService,
    activatedRoute: ActivatedRoute
  ) {
    super(titleService, routerService, activatedRoute);

    this.router = routerService;
  }
  public ngAfterViewChecked(): void {
    this.cdref.detectChanges();
  }

  public override ngOnInit(): void {
    super.ngOnInit();
    this.invokeAnalytics();

    $(window).scroll(function () {
      const scrollHeight = $(this).scrollTop();
      if ((scrollHeight || 10) > 128) {
        $('.message-window').addClass('fixed');
      } else {
        $('.message-window').removeClass('fixed');
      }
    });

    const exists = super.redirectIfNoFederatedId(MpdRoutes.StayConnected);

    if (!exists) {
      return;
    }

    this.subs.push(
      this.locationService
        .getLocations()
        .subscribe((countries: Country[]) => (this.countries = countries))
    );

    this.subs.push(
      this.consentService.getConsent().subscribe((consent: SelectableItem) => {
        this.consentText = consent.name;
        this.formDataService.setConsentId(consent.id);
      })
    );

    const getMarketing = this.marketingService.getPreferences();
    const getPreferences = this.preferencesService.getPreferences(
      this.apiHelperService.getUrl(`profile/${this.federatedId}`)
    );

    this.subs.push(
      combineLatest([getMarketing, getPreferences]).subscribe(
        ([marketingPreferences, prefs]: [SelectableItem[], Preferences]) => {
          if (Object.keys(prefs).length === 0) {
            return;
          }

          if (prefs.hasUnsubscribed) {
            this.router.route([MpdRoutes.StayConnected]);
            this.userService.userHasUnsubscribed(true);
            return;
          }

          // Disabled as part of the MPD Simplification
          // marketingPreferences.forEach((pref: SelectableItem) => {
          //   if (prefs.marketingPreferences.indexOf(pref.id) > -1) {
          //     pref.selected = true;
          //   }
          // });

          this.preferences = marketingPreferences;
          this.formDataService.setMarketingPreferences(this.preferences);

          prefs.areasOfInterest = prefs.areasOfInterest ?? [];

          this.countryId = prefs.countryId;
          this.functionId = prefs.functionId;
          this.specialtyId = prefs.specialtyId;

          if (prefs.areasOfInterest?.length > 0) {
            this.preselectedAreas = prefs.areasOfInterest.map(
              (areaId: string) => {
                return {
                  id: areaId,
                  name: '',
                  selected: true,
                  showTextBox: false,
                };
              }
            );
          }

          this.formDataService.setContactInfo({
            id: prefs.federatedId,
            title: prefs.title,
            firstName: prefs.firstName,
            lastName: prefs.lastName,
            email: prefs.email,
            country: {
              id: prefs.countryId,
              name: '',
              locale: '',
              region: '',
              subregion: '',
              selected: true,
              showTextBox: false,
            },
            state: {
              id: prefs.stateId,
              name: '',
              selected: true,
              showTextBox: false,
            },
          });

          this.formDataService.setProfessionalInfo({
            id: prefs.federatedId,
            hospital: prefs.hospital,
            language: {
              id: prefs.language,
              name: '',
              selected: true,
              showTextBox: false,
            },
            isFellow: prefs.isFellow,
            fellowshipCompleted: prefs.fellowshipCompleted ?? '',
          });

          if (prefs.otherFunction) {
            this.formDataService.setOtherFunction(prefs.otherFunction);
          }
          if (prefs.otherSpecialty) {
            this.formDataService.setOtherSpecialty(prefs.otherSpecialty);
          }

          this.latestConsentVersion = !prefs.latestConsentVersion;
          this.preferencesService.latestConsentVersion =
            prefs.latestConsentVersion;

          this.formHelper.validateAllFormFields(this.formDataService.formGroup);
          this.formDataService.formGroup.updateValueAndValidity();

          this.formDataService.workFunctionCtrl.enable();
        }
      )
    );
  }

  public changedAreasOfInterest(): void {
    this.formDataService.areasOfInterestCtrl.updateValueAndValidity();
    this.formDataService.areasOfInterestCtrl.markAsDirty();

    this.hasReturned = false;
    this.hasError = this.formDataService.formGroup.invalid;
  }

  public changedPreferences(): void {
    this.formDataService.preferencesCtrl.updateValueAndValidity();
    this.formDataService.preferencesCtrl.markAsDirty();

    this.hasReturned = false;
    this.hasError = this.formDataService.formGroup.invalid;
  }

  public showModal(): void {
    this.dialog.open(UnsubscribeModalComponent, {
      data: {
        queryParams: this.queryParams,
        url: `/${this.headerService.currentRegion}/${MpdRoutes.UnsubscribeSurvey}`,
        privacyUrl: this.privacyUrl,
      },
    });
  }

  public invokeAnalytics() {
    window.adobeDataLayer = window.adobeDataLayer || [];
    // We're running the default region for every case as en_US

    window.adobeDataLayer.push({
      event: 'pageLoad',
      pageInfo: {
        pageName: 'stayconnected:managepreferences',
        pageNameCMS: 'stayconnected:managepreferences',
        region: 'en_US',
        section: 'cpc:mpd:managepreferences',
        assetType: 'ManagePreferencesPage',
      },
    });
  }

  public submit(): void {
    this.formHelper.validateAllFormFields(this.formDataService.formGroup);
    this.formDataService.formGroup.updateValueAndValidity();

    if (this.formDataService.formGroup.valid) {
      this.inProgress = true;
      this.hasError = false;
      this.formDataService.formGroup.markAsPristine();
      // Process for getpreferences:
      // we need to initialize data
      const data = this.apiHelperService.appendLocale(
        this.formDataService.convertToProfile()
      );

      this.subs.push(
        this.preferencesService
          .update(
            this.federatedId,
            GORE_URLS[this.headerService.currentRegion as keyof object],
            data
          )
          .subscribe(
            () => (
              (this.savedSuccessfully = true),
              window.adobeDataLayer.push({
                event: 'formSubmit',
                eventInfo: {
                  formName: 'managePreferencesForm',
                },
              })
            ),
            () => (
              (this.savedSuccessfully = false),
              window.adobeDataLayer.push({
                event: 'formSubmitUnsuccessful',
                eventInfo: {
                  formName: 'managePreferencesForm',
                },
              })
            ),
            () => {
              this.hasReturned = true;
              this.inProgress = false;
              this.latestConsentVersion = false;
            }
          )
      );
    } else {
      this.hasError = true;
      window.adobeDataLayer.push({
        event: 'formSubmitUnsuccessful',
        eventInfo: {
          formName: 'managePreferencesForm',
        },
      });
    }
  }
}
