export const GORE_URLS = {
  en: 'goremedical.com',
  la: 'goremedical.com/la',
  ap: 'goremedical.com/ap',
  eu: 'goremedical.com/eu',
  me: 'goremedical.com/me',
};

export const GORE_REGIONS = {
  en: 'North America',
  la: 'Latin America',
  ap: 'Asia Pacific',
  eu: 'Europe',
  me: 'Middle East - Africa',
};
