import { HttpResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GORE_URLS } from '@cpc-mpd/constants/urls.constants';
import { environment } from '@cpc-mpd/environment';
import { Preferences, Specialty, WorkFunction } from '@cpc-mpd/models';
import { FormDataService, HeaderService } from '@cpc-mpd/services';
import {
  Country,
  MpdRoutes,
  PostProfileResponse,
  SelectableItem,
} from '@libs/cross-plat';
import { FormHelperService } from '@libs/cross-plat/validators';
import {
  ApiHelperService,
  CheckboxGroupingComponent,
  ConsentService,
  MarketingPreferencesService,
  PreferencesService,
  RouterService,
  TitleService,
  UserService,
} from '@libs/ui-cdk';
import { MainPageComponent } from '@libs/ui-cdk/components/base';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';

interface QueryParams {
  resub?: string;
}

@Component({
  selector: 'mpd-stay-connected',
  templateUrl: './stay-connected.component.html',
  styleUrls: ['./stay-connected.component.scss'],
})
export class StayConnectedComponent
  extends MainPageComponent
  implements OnInit
{
  @ViewChild('marketingPref') marketingPrefComp!: CheckboxGroupingComponent;
  public consentText = '';
  public preferences: SelectableItem[] = [];
  public clickedSubmit = false;
  public failedSubmit = false;
  public isUnsubscribed = false;
  public userExists = false;
  public removeSpace = false;

  public get areasOfInterest(): SelectableItem[] {
    let areas: SelectableItem[] = [];

    const specialty = this.formDataService.specialtyCtrl.value as Specialty;
    const country = this.formDataService.countryCtrl.value as Country;

    if (specialty) {
      if (!country.region) {
        areas = specialty.areasOfInterest;
      } else {
        areas = specialty.areasOfInterest.filter(
          area =>
            area.regions?.includes(environment.globalRegion) ||
            area.regions?.includes(country.region) ||
            area.regions?.includes(country.subregion)
        );
      }
    } else {
      const func = this.formDataService.workFunctionCtrl.value as WorkFunction;

      if (func?.areasOfInterest) {
        areas = func.areasOfInterest;
      }
    }

    return areas;
  }

  public get contactUsUrl(): string {
    return environment.contactUrl;
  }

  public get privacyUrl(): string {
    return environment.privacyUrl;
  }

  constructor(
    public readonly formDataService: FormDataService,
    private readonly marketingService: MarketingPreferencesService,
    private readonly consentService: ConsentService,
    private readonly preferencesService: PreferencesService<Preferences>,
    private readonly router: Router,
    private readonly formHelper: FormHelperService,
    private readonly userService: UserService,
    private readonly route: ActivatedRoute,
    private readonly headerService: HeaderService,
    private readonly apiHelperService: ApiHelperService,
    titleService: TitleService,
    routerService: RouterService,
    activatedRoute: ActivatedRoute
  ) {
    super(titleService, routerService, activatedRoute);
  }

  public override ngOnInit(): void {
    this.invokeAnalytics();

    super.ngOnInit();
    this.formDataService.reset();

    this.activatedRoute.queryParams.subscribe((params: QueryParams) => {
      this.isUnsubscribed = params.resub == 'true' ? true : false;
    });

    if (this.isUnsubscribed == false) {
      this.subs.push(
        this.userService.userIsUnsubscribed.subscribe(
          (isUnsubscribed: boolean) => {
            this.isUnsubscribed = isUnsubscribed;
          }
        )
      );
    }

    this.subs.push(
      this.marketingService
        .getPreferences()
        .pipe(
          tap((prefs: SelectableItem[]) => {
            prefs.forEach((pref: SelectableItem) => (pref.selected = false));
          })
        )
        .subscribe((prefs: SelectableItem[]) => (this.preferences = prefs))
    );

    this.subs.push(
      this.consentService.getConsent().subscribe((consent: SelectableItem) => {
        this.consentText = consent.name;
        this.formDataService.setConsentId(consent.id);
      })
    );

    this.listenCountryState();
  }

  public invokeAnalytics() {
    window.adobeDataLayer = window.adobeDataLayer || [];
    // TODO
    // Reconsider whether we want to modify the window to use adobeDataLayer

    window.adobeDataLayer.push({
      event: 'pageLoad',
      pageInfo: {
        pageName: 'stayconnected',
        pageNameCMS: 'stayconnected',
        region: 'en_US',
        section: 'cpc:mpd:stayconnected',
        assetType: 'StayConnectedPage',
      },
    });
  }

  listenCountryState() {
    merge(
      this.formDataService.countryCtrl.valueChanges,
      this.formDataService.stateCtrl.valueChanges
    ).subscribe(country => {
      this.formDataService.workFunctionCtrl.reset();
      this.formDataService.preferencesCtrl.reset();
      // Removed as part of MPD simplification
      // this.marketingPrefComp.deselectAll();

      if (!Object.keys(country).includes('states') || !country?.states.length) {
        this.formDataService.workFunctionCtrl.enable();
        console.log(Object.keys(country));
      } else {
        this.formDataService.workFunctionCtrl.disable();
      }
    });
  }
  public submit(): void {
    this.failedSubmit = false;
    this.userExists = false;
    this.formHelper.validateAllFormFields(this.formDataService.formGroup);
    this.formDataService.formGroup.updateValueAndValidity();
    this.clickedSubmit = true;
    if (this.formDataService.formGroup.valid) {
      // Process for save:
      // initialize data
      const data = this.apiHelperService.appendLocale(
        this.formDataService.convertToProfile()
      );

      this.subs.push(
        this.preferencesService
          .save(
            GORE_URLS[this.headerService.currentRegion as keyof object],
            data
          )
          .subscribe(
            (response: PostProfileResponse) => {
              this.userService.userHasUnsubscribed(response.resubscribed);
              window.adobeDataLayer.push({
                event: 'formSubmit',
                eventInfo: {
                  formName: 'stayConnectedForm',
                },
              });
              void this.router.navigate([MpdRoutes.ThankYou], {
                relativeTo: this.route,
              });
            },
            (response: HttpResponse<void>) => {
              if (response.status === 409) {
                this.userExists = true;
              } else {
                this.failedSubmit = true;

                window.adobeDataLayer.push({
                  event: 'formSubmitUnsuccessful',
                  eventInfo: {
                    formName: 'stayConnectedForm',
                  },
                });
              }
            }
          )
      );
    } else {
      window.adobeDataLayer.push({
        event: 'formSubmitUnsuccessful',
        eventInfo: {
          formName: 'stayConnectedForm',
        },
      });
    }
  }
}
