<header class="site-header">
  <div class="spacing-zero">
    <div class="site-header__mobile-wrap spacing-zero">
      <nav
        aria-label="secondary"
        data-linkcontainer="lt_section"
        data-tracklinktext="utility nav">
        <ul class="utility-nav">
          <li>
            <div class="faux-select icon-utility-select-arrow">
              <form method="post" action="https://www.goremedical.com">
                <span class="icon-globe-americas">{{ regionName }}</span>
                <label for="region-select" class="visuallyhidden"
                  >Region selector</label
                >
                <select name="region-select" id="region-select">
                  <option data-icon="globe-americas" value="en">
                    North America
                  </option>
                  <option data-icon="globe-asiaaustralia" value="ap">
                    Asia Pacific
                  </option>
                  <option data-icon="globe-europeafrica" value="eu">
                    Europe
                  </option>
                  <option data-icon="globe-americas" value="la">
                    Latin America
                  </option>
                  <option data-icon="globe-europeafrica" value="me">
                    Middle East - Africa
                  </option>
                </select>
              </form>
            </div>
          </li>
        </ul>
      </nav>
    </div>
    <div class="site-header__inner-wrap spacing-zero">
      <div class="site-header__logo">
        <a
          class="logo"
          aria-label="GORE Medical Homepage"
          [href]="homepageUrl"
          data-tracklinktext="logo"
          ><div class="icon-logo-tagline"></div
        ></a>
      </div>
    </div>
  </div>
</header>
<!-- <div [innerHTML]="headerHtml"></div> -->
