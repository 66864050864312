<div
  class="container"
  fxLayout="row"
  fxLayout.lt-md="column"
  fxLayoutGap="2%"
  fxLayoutAlign="space-between"
  [ngClass]="isReturn ? 'tip-top' : ''">
  <div fxFlex="50%" fxFlex.lt-sm="100%">
    <div class="group">
      <label cpcStar>Choose Function:</label>
      <mat-form-field appearance="outline">
        <mat-select
          class="function-select"
          [formControl]="formDataService.workFunctionCtrl"
          placeholder="Select Function"
          (selectionChange)="functionChanged($event.value)">
          <mat-option *ngFor="let option of validFunctions" [value]="option">
            {{ option.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <cpc-error-collection
        [source]="formDataService.workFunctionCtrl"></cpc-error-collection>
    </div>
  </div>
  <div fxFlex="50%" fxFlex.lt-sm="100%">
    <div class="group" *ngIf="showOtherFunction">
      <label fxHide.lt-sm>&nbsp;</label>
      <mat-form-field appearance="outline">
        <input
          matInput
          name="otherFunction"
          placeholder="Type your Function"
          [formControl]="formDataService.otherFunctionCtrl" />
      </mat-form-field>
      <cpc-error-collection
        [source]="formDataService.otherFunctionCtrl"></cpc-error-collection>
    </div>
    <div class="group" *ngIf="specialties.length > 0">
      <label cpcStar>Type of Physician / Specialty:</label>
      <mat-form-field appearance="outline">
        <mat-select
          class="specialty-select"
          [formControl]="formDataService.specialtyCtrl"
          placeholder="Select Physician/Specialty"
          (selectionChange)="specialtyChanged($event.value)">
          <mat-option *ngFor="let option of specialties" [value]="option">
            {{ option.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <cpc-error-collection
        [source]="formDataService.specialtyCtrl"></cpc-error-collection>
    </div>
    <div class="group" *ngIf="showOtherSpecialty">
      <mat-form-field appearance="outline">
        <input
          matInput
          name="otherSpecialty"
          placeholder="Enter your Type of Specialty"
          [formControl]="formDataService.otherSpecialtyCtrl" />
      </mat-form-field>
      <cpc-error-collection
        [source]="formDataService.otherSpecialtyCtrl"></cpc-error-collection>
    </div>
  </div>
</div>
