import {
  AdvancedFormControl,
  ErrorTypes,
  SelectableItem,
  Country,
  Language,
  POPULATED_ARRAY_VALIDATOR,
  REQUIRED_WHEN_VALIDATOR,
  emailPattern,
} from '@libs/cross-plat';
import { environment } from '@cpc-mpd/environment';
import { Injectable } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import {
  ContactInfo,
  ProfessionalInfo,
  Profile,
  Specialty,
  WorkFunction,
} from '../models/';

@Injectable({
  providedIn: 'root',
})
export class FormDataService {
  private areasOfInterest!: AdvancedFormControl | null;
  private consent!: UntypedFormControl | null;
  private contactInfo!: UntypedFormGroup | null;
  private country!: AdvancedFormControl | null;
  private email!: AdvancedFormControl | null;
  private fellow!: AdvancedFormControl | null;
  private fellowshipCompleted!: AdvancedFormControl | null;
  private firstName!: AdvancedFormControl | null;
  private form!: UntypedFormGroup | null;
  private hospital!: AdvancedFormControl | null;
  private language!: AdvancedFormControl | null;
  private lastName!: AdvancedFormControl | null;
  private otherFunction!: AdvancedFormControl | null;
  private otherSpecialty!: AdvancedFormControl | null;
  private preferences!: AdvancedFormControl | null;
  private professionalInfo!: UntypedFormGroup | null;
  private specialty!: AdvancedFormControl | null;
  private state!: AdvancedFormControl | null;
  private title!: AdvancedFormControl | null;
  private workFunction!: AdvancedFormControl | null;

  private get setOptions(): {
    onlySelf: boolean;
    emitEvent: boolean;
  } {
    return {
      onlySelf: true,
      emitEvent: false,
    };
  }

  public get areasOfInterestCtrl(): AdvancedFormControl {
    if (!this.areasOfInterest) {
      this.areasOfInterest = new AdvancedFormControl(
        []
        // , [Validators.required]
      );
    }
    // this.areasOfInterest.errorTypes = [ErrorTypes.Required];

    return this.areasOfInterest;
  }

  public get consentCtrl(): UntypedFormControl {
    if (!this.consent) {
      this.consent = new UntypedFormControl();
    }

    return this.consent;
  }

  public get contactInfoGroup(): UntypedFormGroup {
    if (!this.contactInfo) {
      this.contactInfo = new UntypedFormGroup({
        title: this.titleCtrl,
        firstName: this.firstNameCtrl,
        lastName: this.lastNameCtrl,
        email: this.emailCtrl,
        country: this.countryCtrl,
        state: this.stateCtrl,
      });
    }

    return this.contactInfo;
  }

  public get countryCtrl(): AdvancedFormControl {
    if (!this.country) {
      this.country = new AdvancedFormControl('', [Validators.required]);
      this.country.errorTypes = [ErrorTypes.Required];
    }

    return this.country;
  }

  public get emailCtrl(): AdvancedFormControl {
    if (!this.email) {
      this.email = new AdvancedFormControl('', [
        Validators.required,
        emailPattern,
      ]);
      this.email.errorTypes = [ErrorTypes.Required, ErrorTypes.Email];
    }

    return this.email;
  }

  public get fellowCtrl(): AdvancedFormControl {
    if (!this.fellow) {
      this.fellow = new AdvancedFormControl(false);
    }

    return this.fellow;
  }

  public get fellowshipCompletedCtrl(): AdvancedFormControl {
    if (!this.fellowshipCompleted) {
      const year = new Date().getFullYear();
      const min = year + environment.minFellowship;
      const max = year + environment.maxFellowship;

      this.fellowshipCompleted = new AdvancedFormControl(undefined, [
        REQUIRED_WHEN_VALIDATOR(this.fellowCtrl, true),
        Validators.min(min),
        Validators.max(max),
      ]);
      this.fellowshipCompleted.min = min;
      this.fellowshipCompleted.max = max;
      this.fellowshipCompleted.errorTypes = [
        ErrorTypes.Required,
        ErrorTypes.Min,
        ErrorTypes.Max,
      ];
    }

    return this.fellowshipCompleted;
  }

  public get firstNameCtrl(): AdvancedFormControl {
    const max = 40;

    if (!this.firstName) {
      this.firstName = new AdvancedFormControl('', [
        Validators.required,
        Validators.maxLength(max),
      ]);
      this.firstName.maxLength = max;
      this.firstName.errorTypes = [ErrorTypes.Required, ErrorTypes.MaxLength];
    }

    return this.firstName;
  }

  public get formGroup(): UntypedFormGroup {
    if (!this.form) {
      this.form = new UntypedFormGroup({
        consent: this.consentCtrl,
        workFunction: this.workFunctionCtrl,
        specialty: this.specialtyCtrl,
        areasOfInterest: this.areasOfInterestCtrl,
        contactInfo: this.contactInfoGroup,
        otherFunction: this.otherFunctionCtrl,
        otherSpecialty: this.otherSpecialtyCtrl,
        preferences: this.preferencesCtrl,
        professionalInfo: this.professionalInfoGroup,
      });
    }

    return this.form;
  }

  public get hospitalCtrl(): AdvancedFormControl {
    const max = 35;

    if (!this.hospital) {
      this.hospital = new AdvancedFormControl('', [Validators.maxLength(max)]);
      this.hospital.maxLength = max;
      this.hospital.errorTypes = [ErrorTypes.MaxLength];
    }

    return this.hospital;
  }

  public get languageCtrl(): AdvancedFormControl {
    if (!this.language) {
      this.language = new AdvancedFormControl('', []);
      this.language.errorTypes = [ErrorTypes.Required];
    }

    return this.language;
  }

  public get lastNameCtrl(): AdvancedFormControl {
    const max = 80;

    if (!this.lastName) {
      this.lastName = new AdvancedFormControl('', [
        Validators.required,
        Validators.maxLength(max),
      ]);
      this.lastName.maxLength = max;
      this.lastName.errorTypes = [ErrorTypes.Required, ErrorTypes.MaxLength];
    }

    return this.lastName;
  }

  public get otherFunctionCtrl(): AdvancedFormControl {
    const max = 50;

    if (!this.otherFunction) {
      this.otherFunction = new AdvancedFormControl('', [
        Validators.maxLength(max),
        REQUIRED_WHEN_VALIDATOR(
          this.workFunctionCtrl,
          (func: WorkFunction) => func.showTextBox
        ),
      ]);

      this.otherFunction.maxLength = max;
      this.otherFunction.errorTypes = [
        ErrorTypes.Required,
        ErrorTypes.MaxLength,
      ];
    }

    return this.otherFunction;
  }

  public get otherSpecialtyCtrl(): AdvancedFormControl {
    const max = 50;

    if (!this.otherSpecialty) {
      this.otherSpecialty = new AdvancedFormControl('', [
        Validators.maxLength(max),
        REQUIRED_WHEN_VALIDATOR(
          this.specialtyCtrl,
          (specialty: SelectableItem) => specialty.showTextBox
        ),
      ]);

      this.otherSpecialty.maxLength = max;
      this.otherSpecialty.errorTypes = [
        ErrorTypes.Required,
        ErrorTypes.MaxLength,
      ];
    }

    return this.otherSpecialty;
  }

  public get preferencesCtrl(): AdvancedFormControl {
    if (!this.preferences) {
      this.preferences = new AdvancedFormControl(
        []
        // [Validators.required, POPULATED_ARRAY_VALIDATOR]
      );
      // this.preferences.errorTypes = [ErrorTypes.Required];
    }

    return this.preferences;
  }

  public get professionalInfoGroup(): UntypedFormGroup {
    if (!this.professionalInfo) {
      this.professionalInfo = new UntypedFormGroup({
        hospital: this.hospitalCtrl,
        language: this.languageCtrl,
        fellow: this.fellowCtrl,
        fellowshipCompleted: this.fellowshipCompletedCtrl,
      });
    }

    return this.professionalInfo;
  }

  public get specialtyCtrl(): AdvancedFormControl {
    if (!this.specialty) {
      this.specialty = new AdvancedFormControl('', [
        REQUIRED_WHEN_VALIDATOR(
          this.workFunctionCtrl,
          (workFn: WorkFunction) => workFn.specialties?.length
        ),
      ]);
      this.specialty.errorTypes = [ErrorTypes.Required];
    }

    return this.specialty;
  }

  public get stateCtrl(): AdvancedFormControl {
    if (!this.state) {
      this.state = new AdvancedFormControl('', [
        REQUIRED_WHEN_VALIDATOR(
          this.countryCtrl,
          (country: Country) => country.states?.length
        ),
      ]);
      this.state.errorTypes = [ErrorTypes.Required];
    }

    return this.state;
  }

  public get titleCtrl(): AdvancedFormControl {
    if (!this.title) {
      this.title = new AdvancedFormControl('');
    }

    return this.title;
  }

  public get workFunctionCtrl(): AdvancedFormControl {
    if (!this.workFunction) {
      this.workFunction = new AdvancedFormControl(
        { value: '', disabled: true },
        [Validators.required]
      );
      this.workFunction.errorTypes = [ErrorTypes.Required];
    }

    return this.workFunction;
  }

  public convertToProfile(): Profile {
    const areasOfInterest =
      (this.areasOfInterestCtrl.value as SelectableItem[]) || [];
    const country = this.countryCtrl.value as Country;
    const language = this.languageCtrl.value as Language;
    const marketingPreferences =
      (this.preferencesCtrl.value as SelectableItem[]) || [];
    const specialty = this.specialtyCtrl.value as Specialty;
    const state = this.stateCtrl.value as SelectableItem;
    const title = this.titleCtrl.value as SelectableItem;
    const workFunction = this.workFunctionCtrl.value as WorkFunction;

    // Use the name property for title because as of 5/14/21
    // Ping will be saving this as text.
    return {
      consentId: (this.consentCtrl.value as string) || '',
      title: title ? title.name : '',
      email: this.emailCtrl.value as string,
      firstName: this.firstNameCtrl.value as string,
      lastName: this.lastNameCtrl.value as string,
      countryId: country ? country.id : '',
      stateId: state ? state.id : '',
      function: workFunction ? workFunction.name : '',
      functionId: workFunction ? workFunction.id : '',
      otherFunction: (this.otherFunctionCtrl.value as string) || '',
      specialty: specialty ? specialty.name : '',
      specialtyId: specialty ? specialty.id : '',
      otherSpecialty: (this.otherSpecialtyCtrl.value as string) || '',
      marketingPreferences: marketingPreferences.map(
        (pref: SelectableItem) => pref.id
      ),
      areasOfInterest: areasOfInterest.map((aoi: SelectableItem) => aoi.id),
      hospital: this.hospitalCtrl.value as string,
      language: language || undefined,
      isFellow: this.fellowCtrl.value as boolean,
      fellowshipCompleted:
        (this.fellowshipCompletedCtrl.value as string)?.toString() || '',
    };
  }

  public reset(): void {
    this.areasOfInterest = null;
    this.contactInfo = null;
    this.country = null;
    this.email = null;
    this.fellow = null;
    this.fellowshipCompleted = null;
    this.firstName = null;
    this.form = null;
    this.hospital = null;
    this.language = null;
    this.lastName = null;
    this.otherFunction = null;
    this.otherSpecialty = null;
    this.preferences = null;
    this.professionalInfo = null;
    this.specialty = null;
    this.state = null;
    this.title = null;
    this.workFunction = null;
  }

  public setConsentId(id: string): void {
    this.consentCtrl.setValue(id, this.setOptions);
  }

  public setContactInfo(contactInfo: ContactInfo): void {
    this.titleCtrl.setValue(contactInfo.title, this.setOptions);
    this.firstNameCtrl.setValue(contactInfo.firstName, this.setOptions);
    this.lastNameCtrl.setValue(contactInfo.lastName, this.setOptions);
    this.emailCtrl.setValue(contactInfo.email, this.setOptions);
    this.stateCtrl.setValue(contactInfo.state, this.setOptions);

    this.setCountry(contactInfo.country);
  }

  public setCountry(country: Country): void {
    this.countryCtrl.setValue(country, this.setOptions);
  }

  public setFellowshipCompleted(year: string): void {
    this.fellowshipCompletedCtrl.setValue(year, this.setOptions);
    this.fellowshipCompletedCtrl.updateValueAndValidity({
      onlySelf: true,
      emitEvent: false,
    });
  }

  public setFunction(func: WorkFunction): void {
    this.workFunctionCtrl.setValue(func, this.setOptions);
  }

  public setAreasOfInterest(aoi: SelectableItem[]): void {
    this.areasOfInterestCtrl.setValue(aoi, this.setOptions);
  }

  public setMarketingPreferences(prefs: SelectableItem[]): void {
    this.preferencesCtrl.setValue(prefs, this.setOptions);
  }

  public setOtherFunction(name: string): void {
    this.otherFunctionCtrl.setValue(name, this.setOptions);
  }

  public setOtherSpecialty(name: string): void {
    this.otherSpecialtyCtrl.setValue(name, this.setOptions);
  }

  public setProfessionalInfo(profInfo: ProfessionalInfo): void {
    this.hospitalCtrl.setValue(profInfo.hospital, this.setOptions);
    this.languageCtrl.setValue(profInfo.language, this.setOptions);
    this.fellowCtrl.setValue(profInfo.isFellow, this.setOptions);
    this.fellowshipCompletedCtrl.setValue(
      profInfo.fellowshipCompleted,
      this.setOptions
    );
  }

  public setSpecialty(specialty: Specialty | null = null): void {
    this.specialtyCtrl.setValue(specialty, this.setOptions);

    if (!specialty?.showTextBox) {
      this.setOtherSpecialty('');
    }
  }

  public setState(state: SelectableItem | null): void {
    this.stateCtrl.setValue(state ? state.id : '', this.setOptions);
  }
}
