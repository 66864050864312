<footer>
  <div
    class="container site-footer print-hide"
    fxLayout="row"
    fxLayoutWrap
    fxLayoutGap="2%"
    fxLayoutAlign="center">
    <div class="site-footer__inner spacing-half" fxflex="70%" fxflex.xs="90%">
      <div id="block-social" class="main-footer__social-container"></div>

      <ul class="footer-nav spacing-half">
        <li>
          <a href="" class="onetrust-infodisplay">Cookie Settings</a>
        </li>
        <li *ngIf="currentRegion === 'en' || currentRegion === 'eu'">
          <a [attr.href]="mpdUrl + 'support'">Support</a>
        </li>
        <li>
          <a [attr.href]="mpdUrl + 'legal-information'">Legal Information</a>
        </li>
        <li>
          <a href="https://www.gore.com/privacy">Privacy Notice</a>
        </li>
        <li>
          <a [attr.href]="mpdUrl + 'regulatory-information'"
            >Regulatory Information</a
          >
        </li>
        <li>
          <a [attr.href]="mpdUrl + 'trademarks'">Trademarks</a>
        </li>
        <li *ngIf="currentRegion !== 'en' && currentRegion !== 'eu'">
          <a [attr.href]="mpdUrl + 'contact-us'">Contact Us</a>
        </li>
        <li *ngIf="currentRegion === 'en'">
          <a
            class="not-share-message"
            (click)="showMessage()"
            aria-current="page"
            >Do Not Share My Personal Information</a
          >
        </li>
        <li>
          <a href="https://www.gore.com/resources/modern-slavery-statement"
            >Modern Slavery Statement</a
          >
        </li>
        <li><a href="https://www.gore.com/" target="_blank">Gore.com</a></li>
      </ul>

      <p class="smallest">
        Copyright © 2002-2024 | W. L. Gore &amp; Associates, Inc. |
        <strong>Products listed may not be available in all markets.</strong>
      </p>
    </div>
  </div>
</footer>
<!-- <div [innerHTML]="footerHtml"></div> -->
